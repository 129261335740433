#member-manager {
  .tp--page {
    &__table-header {
      margin-bottom: 20px;
      margin-left: 33px;
      margin-right: 40px;
      overflow: hidden;

      h2 {
        font-size: 24px;
        line-height: 36px;
        font-weight: 800;
        color: $tp--primary-background-color;
        float: left;
      }

      .tp--export {
        float: right;

        .pf-c-button {
          height: 36px;
          padding-left: 38px;
          padding-right: 38px;

          @media screen and (max-width: 500px) {
            margin-top: 20px;
            float: left;
          }

          .pf-c-spinner {
            --pf-c-spinner--diameter: 20px;
            --pf-c-spinner--Width: 20px;
            --pf-c-spinner--Height: 20px;
            --pf-c-spinner__clipper--Width: 20px;
            --pf-c-spinner__clipper--Height: 20px;
          }
        }
      }
    }
  }
  .tp--suspended {
    background-color: #e6e6e6;
    color: #737679;
  }

  .tp--blocked {
    background-color: #f3d2d2;
    color: #737679;
  }
}

.tp--confirm-export {
  .pf-c-button.pf-m-link {
    margin-left: 30px;
  }
}
